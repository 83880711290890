@charset "utf-8";

/* reset */
body {font-family: 'Nanum Barun Gothic', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;font-size:12px;font-weight:400;line-height:1.2;letter-spacing:0;color:#333;background-color:#fff;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-osx-font-smoothing:grayscale;}
html, body {
    height:100%;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,form,fieldset,p,button,iframe,th,td{margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,nav,section,summary{display:block;margin:0;padding:0;}
h1,h2,h3,h4,h5,h6,dt{font-weight:normal;}
img,fieldset,iframe,button{border:0 none;}
li{list-style:none;}
button{font-family: 'Nanum Barun Gothic', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;border:0 none;font-size:12px;color:#333333;text-decoration:none;outline-offset:-1px;}
table{ border-collapse:collapse;border-spacing:0; }
th, td{border-collapse:collapse;}
legend, caption, hr{display:none;}
select, input, textarea {font-family: 'Nanum Barun Gothic', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;color:#333333;}
i, em, u, cite {font-style:normal;}
strong{font-weight:normal;}
img{border:0; vertical-align:middle;}
a{color:#353535;text-decoration:none;outline-offset:-1px;}
address{font-style:normal;}
body:before{position:fixed;left:0;top:0;width:100%;height:0;content:'';}/* fix webkit bug(scroll event delay) */
figure.img img{width:100%;}
/*
    -webkit-appearance:none;

    display:-webkit-flex;display:flex;
    -webkit-flex:1;flex:1;

    -webkit-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box;

    transition:all 0.175s cubic-bezier(.215,.61,.355,1);-webkit-transition:all 0.175s cubic-bezier(.215,.61,.355,1);-moz-transition:all 0.175s cubic-bezier(.215,.61,.355,1);-o-transition:all 0.175s cubic-bezier(.215,.61,.355,1);
*/

::-webkit-input-placeholder { color:#aaaaaa; }
::-moz-placeholder { color:#aaaaaa; } /* firefox 19+ */
:-ms-input-placeholder { color:#aaaaaa; } /* ie */
input:-moz-placeholder { color:#aaaaaa; }
